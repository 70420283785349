import { type OperationDefinition } from '@/levity/flows/types';

import { CopyTagTypes } from '../../../flows/types';
import { CUSTOM } from '../constants';

type YoutubeOperationKeys = 'PostComment' | 'GetAllComments' | 'DeleteComment' | 'HoldCommentForReview';

export const youtubeOperationsDefinition: Record<YoutubeOperationKeys, OperationDefinition> = {
  PostComment: {
    id: 'youtube-post-comment',
    name: 'Reply Comment',
    description: 'Reply a comment on a YouTube video',
    icon: 'youtube',
    hasDynamicInputs: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Reply a comment',
        tooltips: {
          description: 'Reply a comment on a YouTube video based on the received input.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Reply comment based on ${CUSTOM}`,
      },
    },
  },
  GetAllComments: {
    id: 'youtube-get-all-comments',
    name: 'Get all video comments',
    description: 'Get all video comments for all your videos',
    icon: 'youtube',
    hasDynamicInputs: false,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Get all comments',
        tooltips: {
          description: 'Get all your comments from all your videos.',
        },
      },
      actionDefinition: {
        elementTags: [],
        action: `Get all your comments from all your videos.`,
      },
    },
  },
  DeleteComment: {
    id: 'youtube-delete-comment',
    name: 'Delete Comment',
    description: 'Delete a comment on a YouTube video.',
    icon: 'youtube',
    hasDynamicInputs: false,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Delete Comment',
        tooltips: {
          description: 'Delete a comment on a YouTube video based on the comment id.',
        },
      },
      interactiveParameters: {
        label: 'Delete comment based on classification',
        title: 'Delete comment based on classification',
        tooltips: {
          description: `You need to set the label 'Hate' in your classifier block to automatically delete the comment.`,
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Delete comment based on ${CUSTOM}`,
      },
    },
  },
  HoldCommentForReview: {
    id: 'youtube-hold-comment-for-review',
    name: 'Hold comment for review',
    description: 'Hold comment for review on a YouTube video.',
    icon: 'youtube',
    hasDynamicInputs: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Hold comment for review',
        tooltips: {
          description: 'Hold a comment for review on a YouTube video based on the comment id.',
        },
      },
      interactiveParameters: {
        label: 'Hold comment for review based on classification',
        title: 'Hold comment for review based on classification',
        tooltips: {
          description: `You need to set the label 'To Review' in your classifier block to automatically hold the comment for review.`,
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Hold comments for review based on ${CUSTOM}`,
      },
    },
  },
};
