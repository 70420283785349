// #region PARAMS
export const PARAMS = {
  FLOW_ID: 'flowId',
  AIBLOCK_ID: 'aiBlockId',
  CATEGORIES: 'categories',
  CS_CATEGORY_ID: 'categoryId',
  CS_DOMAIN: 'domain',
  USER_EMAIL: 'userEmail',
  EMAIL_ID: 'emailId',
  AIRTABLE_AUTH_TOKEN: 'airtableAuthToken',
  GSHEETS_AUTH_TOKEN: 'gsheetsAuthToken',
  GMAIL_AUTH_TOKEN: 'gmailAuthToken',
  YOUTUBE_AUTH_TOKEN: 'youtubeAuthToken',
  OUTLOOK_AUTH_TOKEN: 'outlookAuthToken',
  INPUT: 'input',
  NAME: 'name',
  OUTPUT: 'output',
  PRISMA: 'prisma',
  TENANT_ID: 'tenantId',
  SEARCH_FILTER_TEXT: 'gDriveId',
  GDRIVE_ID: 'searchFilter',
  GSHEET_FILE_ID: 'gSheetFileId',
  GSHEET_WORKSHEET_ID: 'gSheetWorksheetId',
  TEXT: 'text',
  TICKET_ID: 'ticketId',
  TICKET: 'ticket',
  TAG: 'tag',
  TIMESTAMP: 'timestamp',
  METADATA: 'metadata',
  ZENDESK_AUTH_TOKEN: 'authToken',
  FRONT_AUTH_TOKEN: 'authToken',
  HUBSPOT_AUTH_TOKEN: 'authToken',
  CALL_SOURCE: 'callSource',
  NODE_ID: 'nodeId',
  LAST_TIMESTAMP: 'lastTimestamp',
  UPDATE_NODE: 'updateNode',
  SLACK_CHANNEL_ID: 'slackChannelId',
  SLACK_AUTH_TOKEN: 'slackAuthToken',
  REQUEST_URL: 'requestUrl',
  REQUEST_METHOD: 'requestMethod',
  REQUEST_HEADERS: 'requestHeaders',
  REQUEST_BODY: 'requestBody',
  CUSTOM_CODE: 'customCode',
  CUSTOM_CODE_INPUTS: 'customCodeInputs',
  CUSTOM_CODE_OUTPUT_MAPPING: 'customCodeOutputMapping',
  FRESHDESK_AUTH_TOKEN: 'authToken',
  EXTERNAL_PARAMS: 'externalParams',
  CONTEXT_STORE: 'contextStore',
};
// #endregion

// #region RESPONSE_CODES
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum RESPONSE_CODES {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}
// #endregion

// #region ERROR_MESSAGES
export const INFORMATION_MESSAGES = {
  YOUTUBE_COMMENT_DELETED_SUCCESSFULLY: {
    informationCode: 'youtubeCommentDeletedSuccessfully',
    description: 'Comment deleted successfully.',
  },
  YOUTUBE_COMMENT_HELD_FOR_REVIEW_SUCCESSFULLY: {
    informationCode: 'youtubeHeldForReviewSuccessfully',
    description: 'Comment held for review successfully.',
  },
};
export const ERROR_MESSAGES = {
  MISSING_INPUTS: {
    errorCode: 'missingInputs',
    description: 'Some inputs are missing: ',
  },
  INSECURE_URL: {
    errorCode: 'insecureUrl',
    description: 'The provided url must use HTTPS.',
  },
  REQUEST_FAILED: {
    errorCode: 'requestFailed',
    description: 'The HTTPS request failed.',
  },
  WEBHOOK_REQUEST_FAILED: {
    errorCode: 'webhookRequestFailed',
    description: 'The Webhook request failed. Check it is not empty and try again.',
  },
  MISSING_REQUEST_TYPE: {
    errorCode: 'missingRequestType',
    description: 'A request type needs to be provided',
  },
  MISSING_URL: {
    errorCode: 'missingUrl',
    description: 'An URL needs to be provided',
  },
  PAYLOAD_LIMIT_EXCEEDED: {
    errorCode: 'payloadLimitExceeded',
    description: 'The payload exceeds the maximum allowed size of 20K characters.',
  },
  MISSING_AUTH: {
    errorCode: 'missingAuthentication',
    description: 'Connection could not be retrieved.',
  },
  CATEGORY_NOT_FOUND: {
    errorCode: 'categoryNotFound',
    description: 'No categories found for the specified message.',
  },
  TAG_COULD_NOT_BE_CREATED: {
    errorCode: 'tagCouldNotBeCreated',
    description: 'Tag could not be created.',
  },
  TAG_COULD_NOT_BE_ADDED: {
    errorCode: 'tagCouldNotBeAdded',
    description: 'Tag could not be added.',
  },
  NO_ITEMS: {
    errorCode: 'noItems',
    description: 'No items found.',
  },
  MESSAGE_NOT_FOUND: {
    errorCode: 'messageNotFound',
    description: 'Message not found.',
  },
  TICKETS_NOT_FOUND: {
    errorCode: 'ticketsNotFound',
    description: 'Tickets not found.',
  },
  TICKET_RESULTS_INVALID: {
    errorCode: 'ticketResultsInvalid',
    description: 'One or more tickets did not return a valid result.',
  },
  ERROR_GETTING_EMAIL: {
    errorCode: 'errorGettingEmail',
    description: 'An error happened when trying to get email details.',
  },
  ERROR_GETTING_CATEGORIES: {
    errorCode: 'errorGettingCategories',
    description: 'An error happened when trying to get categories.',
  },
  ERROR_UPDATING_EMAIL: {
    errorCode: 'errorUpdatingEmail',
    description: 'An error happened when trying to update the email.',
  },
  ERROR_CLASSIFYING_TEXT: {
    errorCode: 'errorClassifyingText',
    description: 'An error happened when trying to classify a text.',
  },
  OPERATION_ERROR: {
    errorCode: 'operationError',
    description: 'An error ocurred when executing the operation.',
  },
  CUSTOM_CODE_EXECUTION_ERROR: {
    errorCode: 'customCodeExecutionError',
    description: 'An error ocurred when executing the custom code.',
  },
  LOGGING_ERROR: {
    errorCode: 'loggingError',
    description: 'An error ocurred when logging the custom input.',
  },
  EXECUTION_ERROR: {
    errorCode: 'executionError',
    description: 'An error ocurred when executing the flow.',
  },
  SUBDOMAIN_NOT_FOUND: {
    errorCode: 'missingSubdomain',
    description: 'Subdomain is undefined',
  },
  CONNECTION_EMAIL_NOT_FOUND: {
    errorCode: 'missingConnectionEmail',
    description: 'Connection email could not be retrieved',
  },
  YOUTUBE_COMMENT_NOT_FOUND: {
    errorCode: 'youtubeCommentNotFound',
    description: 'Youtube comment not found',
  },
  YOUTUBE_HATE_COMMENT_NOT_FOUND: {
    errorCode: 'youtubeHateCommentNotFound',
    description: 'Youtube hate comment not found',
  },
  YOUTUBE_CHANNEL_NOT_FOUND: {
    errorCode: 'youtubeChannelNotFound',
    description: 'No YouTube channel found for the authenticated user.',
  },
  SLACK_TEAM_ERROR: {
    errorCode: 'slackTeamError',
    description: 'Could not get Slack workspace info.',
  },
  WORKSHEET_NOT_FOUND: {
    errorCode: 'noWorksheet',
    description: 'No worksheet found.',
  },
  ROW_TITLE_CANNOT_BE_CREATED: {
    errorCode: 'rowTitleCannotBeCreated',
    description: 'Row title could not be automatically created.',
  },
  ROW_CANNOT_BE_ADDED: {
    errorCode: 'rowCannotBeAdded',
    description: 'Row could not be added.',
  },
  FRONT: {
    INBOXES_NOT_FOUND: {
      errorCode: 'inboxError',
      description: 'Could not find inboxes',
    },
    CONVERSATION_NOT_FOUND: {
      errorCode: 'conversationNotFound',
      description: 'Conversation not found.',
    },
    CONVERSATION_RESULTS_INVALID: {
      errorCode: 'conversationResultsInvalid',
      description: 'One or more conversations did not return a valid result.',
    },
  },
  ERROR_EMAIL_NOT_VALID: {
    errorCode: 'emailNotValid',
    description: 'Invalid email address',
  },
};
// #endregion

// #region EXCEPTIONS
export const EXCEPTIONS = {
  FlowDeletedInDBException: new Error('This flow was deleted from the app', {}),
};
// #endregion

export const CONNECTION_CONFIG_PARAMS = {
  subdomain: 'subdomain',
};

export interface ConectionConfigParamsType {
  [key: string]: string;
}

// #region UI Copies
export const TAG = '%%TAG%%';
export const CUSTOM = '%%CUSTOM%%';
