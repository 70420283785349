import { type OperationDefinition } from '@/levity/flows/types';
import { CopyTagTypes } from '@/levity/flows/types';

import { CUSTOM } from '../constants';

type NetworkOperationKeys = 'ExecuteHttpRequest';

export const networkOperationsDefinition: Record<NetworkOperationKeys, OperationDefinition> = {
  ExecuteHttpRequest: {
    id: 'network-execute-http-request',
    name: 'Executes an HTTPS request',
    icon: 'network',
    description: 'Executes an HTTPS request',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: true,
    requiresAuth: false,
    copies: {
      operationContext: {
        title: 'Executes an HTTPS request',
        tooltips: {
          description: 'Executes an HTTPS request with given payload to given URL.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Executes an HTTPS request to ${CUSTOM}`,
      },
    },
  },
};
