import React from 'react';

import { IconProps } from '../icons.types';

export const WaitIcon: React.FC<IconProps> = (props) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9 9L4.725 5.79375C4.65514 5.74135 4.59844 5.67341 4.55938 5.59531C4.52033 5.5172 4.5 5.43108 4.5 5.34375V2.8125C4.5 2.66332 4.55926 2.52024 4.66475 2.41475C4.77024 2.30926 4.91332 2.25 5.0625 2.25H12.9375C13.0867 2.25 13.2298 2.30926 13.3352 2.41475C13.4407 2.52024 13.5 2.66332 13.5 2.8125V5.31852C13.5 5.40549 13.4798 5.49127 13.4411 5.56913C13.4023 5.64699 13.3461 5.71481 13.2767 5.76725L9 9Z'
      stroke='#8F9098'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9 9L4.725 12.2063C4.65514 12.2586 4.59844 12.3266 4.55938 12.4047C4.52033 12.4828 4.5 12.5689 4.5 12.6562V15.1875C4.5 15.3367 4.55926 15.4798 4.66475 15.5852C4.77024 15.6907 4.91332 15.75 5.0625 15.75H12.9375C13.0867 15.75 13.2298 15.6907 13.3352 15.5852C13.4407 15.4798 13.5 15.3367 13.5 15.1875V12.6815C13.5 12.5945 13.4798 12.5087 13.4411 12.4309C13.4023 12.353 13.3461 12.2852 13.2767 12.2328L9 9Z'
      stroke='#8F9098'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M4.5 4.5H13.5' stroke='#8F9098' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
