export enum EventTypes {
  AlertDismissed = 'alert-dismissed',
  AlertDisplayed = 'alert-displayed',
  CustomEvent = 'custom-event',
  CustomLogFromNode = 'custom-log-from-node',
  InputChanged = 'input-changed',
  ItemClicked = 'item-clicked',
  ModalClosed = 'modal-closed',
  ModalOpened = 'modal-opened',
  PageVisited = 'page-visited',
  SurveyResponse = 'survey sent',
  Warning = 'warning',
}
