import { type OperationDefinition } from '@/levity/flows/types';
import { CopyTagTypes } from '@/levity/flows/types';

import { CUSTOM } from '../constants';

type DevelopmentOperationKeys = 'LogResult';

export const developmentOperationsDefinition: Record<DevelopmentOperationKeys, OperationDefinition> = {
  LogResult: {
    id: 'development-log-result',
    name: 'Log Custom Input',
    icon: 'development',
    description: 'Log a custom input to the logger system.',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: true,
    requiresAuth: false,
    copies: {
      operationContext: {
        title: 'Log custom input',
        tooltips: {
          description: 'Log custom input',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Log ${CUSTOM} in the logger system`,
      },
    },
  },
};
