import { type OperationDefinition } from '@/levity/flows/types';

import { CopyTagTypes } from '../../../../flows/types';
import { CUSTOM, TAG } from '../../constants';

type GmailOperationKeys =
  | 'GetCategories'
  | 'CreateCategory'
  | 'CategorizeMessage'
  | 'RemoveCategoryFromMessage'
  | 'GetMessage'
  | 'GetMessages'
  | 'FilterMessagesByTimestamp'
  | 'GetLatestEmail'
  | 'CreateDraft'
  | 'GetAllMessages'
  | 'CategorizeListOfMessages'
  | 'ReplyToEmail'
  | 'ForwardEmail';

export const gmailOperationsDefinition: Record<GmailOperationKeys, OperationDefinition> = {
  GetCategories: {
    id: 'gmail-get-categories',
    name: 'Get Categories',
    description: 'get Gmail labels',
    hasDynamicInputs: false,
    requiresAuth: true,
  },
  CreateCategory: {
    id: 'gmail-create-category',
    name: 'Create Category',
    description: 'create a Gmail label',
    hasDynamicInputs: true,
    requiresAuth: true,
  },
  CategorizeMessage: {
    id: 'gmail-categorize-message',
    name: 'Categorize Message',
    description: 'label an email',
    hasDynamicInputs: true,
    requiresAuth: true,
  },
  RemoveCategoryFromMessage: {
    id: 'gmail-remove-category-from-message',
    name: 'Remove Category From Message',
    description: 'unlabel an email',
    hasDynamicInputs: false,
    requiresAuth: true,
  },
  GetMessage: {
    id: 'gmail-get-message',
    name: 'Get Message',
    description: 'get specific email',
    hasDynamicInputs: false,
    requiresAuth: true,
  },
  GetMessages: {
    id: 'gmail-get-messages',
    name: 'Get Messages',
    description: 'get latest emails',
    hasDynamicInputs: false,
    requiresAuth: true,
  },
  FilterMessagesByTimestamp: {
    id: 'gmail-filter-messages-by-timestamp',
    name: 'Filter Messages By Timestamp',
    description: 'filter emails by timestamp',
    hasDynamicInputs: false,
    requiresAuth: true,
  },
  GetLatestEmail: {
    id: 'gmail-get-latest-email',
    name: 'Get Latest Email',
    description: 'Latest email received',
    hasDynamicInputs: false,
    requiresAuth: true,
  },
  CreateDraft: {
    id: 'gmail-create-draft',
    name: 'Create Draft Reply',
    description: 'Create a draft reply email',
    icon: 'gmail',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Create a draft reply email',
        tooltips: {
          description:
            'Write your draft email here. You can also include text generated from previous steps in this workflow.',
        },
      },
      interactiveParameters: {
        label: 'Draft email text',
        title: 'Draft email text in Gmail',
        tooltips: {
          description:
            'Write your draft email here. You can also include text generated from previous steps in this workflow.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: [],
        action: `Create a draft reply with ${CUSTOM}`,
      },
    },
  },
  GetAllMessages: {
    id: 'gmail-get-all-new-messages',
    name: 'Get All New Emails',
    description: 'New email received',
    icon: 'gmail',
    hasDynamicInputs: false,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'When email is received',
        tooltips: {
          description: 'Specify the mailboxes and labels to fetch the emails from',
        },
      },
      actionDefinition: {
        elementTags: ['New email'],
        action: `When ${TAG} is received`,
      },
    },
  },
  CategorizeListOfMessages: {
    id: 'gmail-categorize-list-of-messages',
    name: 'Categorize list of messages',
    description: 'Label email',
    icon: 'gmail',
    hasDynamicInputs: true,
    requiresAuth: true,
    copies: {
      operationContext: {
        title: 'Update label',
        tooltips: {
          description: 'Choose a value to update the label in your incoming emails in Gmail.',
        },
      },
      interactiveParameters: {
        label: 'Update email label with',
        title: 'New email in Gmail',
        tooltips: {
          description: 'Choose a value to update the label of your incoming emails in Gmail.',
        },
      },
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: ['New email'],
        action: `Then update ${TAG} with ${CUSTOM}`,
      },
    },
  },
  ReplyToEmail: {
    id: 'gmail-reply-to-email',
    name: 'Reply to new email',
    description: 'Reply to new email',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    icon: 'gmail',
    copies: {
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: ['New email'],
        action: `Reply to new email with ${CUSTOM}`,
      },
      operationContext: {
        title: 'Reply to new email',
      },
      interactiveParameters: {
        label: 'Email body',
        title: 'Email reply to be sent',
        tooltips: {
          description:
            'Write your reply here. You can also include text generated from previous steps in this workflow.',
        },
      },
    },
  },
  ForwardEmail: {
    id: 'gmail-forward-email',
    name: 'Forward email',
    description: 'Forward email',
    hasDynamicInputs: true,
    hideDefaultDynamicInput: true,
    requiresAuth: true,
    icon: 'gmail',
    copies: {
      actionDefinition: {
        customTags: [{ type: CopyTagTypes.CURRENT_NODE_INPUTS }],
        elementTags: ['New email'],
        action: `Forward email to ${CUSTOM}`,
      },
      operationContext: {
        title: 'Forward email',
      },
      interactiveParameters: {
        label: 'Email body',
        title: 'Email reply to be sent',
        tooltips: {
          description:
            'Write your reply here. You can also include text generated from previous steps in this workflow.',
        },
      },
    },
  },
};
